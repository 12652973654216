@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

$red: #ea1c2d;
$blue: #0071ba;

$primary: $red;
$danger: $red;

$info: $blue;
$link: $blue;

$navbar-height: 5rem;
$navbar-item-img-max-height: 3rem;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero {
  &.has-background {
    position: relative;
    overflow: hidden;
  }

  &-background {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;

    &.is-translucent {
      opacity: 0.3;
    }
  }
}

@import '../node_modules/bulma/bulma.sass';
@import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
